import React, { useState, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/zh'
import {
  Banner,
  Seo,
  SummaryTitle,
  ScanCodeConsultButton,
  ImagePreview,
  MoreResources,
  PageBottomCard,
} from 'components/index'
import classNames from 'classnames'

import * as styles from './index.module.less'
import ProductCards from 'components/HealthCom/ProductCards'
import MousewheelTab from 'components/Solutions/MousewheelTab'
import TestimonyCard from 'components/Solutions/TestimonyCard'

const Part2Item = ({ img, title, desc }) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.imgWrapper}>
        <img src={img?.publicURL ?? img} alt="" />
      </div>
      <div className={styles.cardCotent}>
        <div className={styles.cardTitle}>{title}</div>
        <div className={styles.cardDesc}>{desc}</div>
      </div>
    </div>
  )
}

const overseasBusiness = (props) => {
  const { data } = props
  const { overseasBusinessYaml } = data
  const { part1, part2, part2_qrcode, part3, part4, part5 } = overseasBusinessYaml
  const productCardsData = ['神策分析云', '神策用户画像', '神策营销云', '神策 A/B Testing']
  const part3TabsRef = useRef<any>()

  useEffect(() => {
    console.log(part3TabsRef.current?.clientWidth)
  }, [])

  //part3被选中的index
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  //part3需要展示的内容
  const [part3section, setPart3section] = useState(part3.sections[activeTabIndex])

  //设置part3被选中的index
  const handleTabClick = (tabIndex) => {
    setActiveTabIndex(tabIndex)
    setPart3section(part3.sections[tabIndex])
  }

  return (
    <Layout {...props}>
      <Seo
        title="出海企业数字化 | 一站式数据分析平台- 神策数据"
        description="针对出海企业的数字化转型需求，神策数据打造了更加适配出海业务的一站式数据分析洞察平台！为跨境电商、游戏、金融、文娱等行业拓展海外业务提供数据支撑，助力企业打造数字化营销闭环，加速实现数字化转型和业务升级！"
        keywords="企业出海,品牌出海,海外推广,出海业务,数字化转型,数据分析"
        saTitle="官网 - 产品（行业解决方案）- 出海企业数字化"
      />
      <div className={styles.overseasBusinessRoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title={part1?.title}
            desc={part1?.desc}
            buttons={part1?.buttons}
            // img={part1.banner?.publicURL ?? part1.banner}
            className={classNames(styles.banner)}
            h1tag={true}
          />
        </div>
        <div className={classNames(styles.wWarrper, styles.part2)}>
          <SummaryTitle title="更适配出海" />
          <SummaryTitle title="更多行业经验的大数据平台" />
          <div className={`${styles.part2List} flex justify-center`}>
            {part2.map((item) => (
              <Part2Item {...item} key={item?.title} />
            ))}
          </div>
          <div className="flex justify-center md:mt-[30px] mt-[3rem]">
            <ScanCodeConsultButton
              buttonText="立即咨询"
              imgBottomText="微信扫码 立即咨询"
              qrcode={part2_qrcode?.publicURL}
            />
          </div>
          <div className={styles.part3tabs}>
            <div className={styles.tabs} ref={part3TabsRef}>
              {part3.tabs.map((tab, index) => (
                <div
                  key={index}
                  className={classNames({
                    [styles.tab]: true,
                    [styles.active]: activeTabIndex === index,
                    'w-[300px]': true,
                  })}
                  onClick={() => handleTabClick(index)}
                >
                  {tab.tabName}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classNames(styles.bWarrper, styles.part3)}>
          <MousewheelTab sections={part3section} />
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="客户证言" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            {/* <ImagePreview imageUrl={part4.img?.publicURL ?? part4.img} alt={part4?.alt || ''} /> */}
            <TestimonyCard {...part4} />
          </div>
          {/* {part4?.qrCode && (
            <div className="flex justify-center md:mt-[30px] mt-[3rem] ">
              <ScanCodeConsultButton
                buttonText="扫码咨询方案顾问"
                imgBottomText="扫码咨询方案顾问"
                qrcode={part4.qrCode?.publicURL ?? part4.qrCode}
              />
            </div>
          )} */}
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="更多干货" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <MoreResources data={part5} />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="了解神策产品" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <ProductCards data={productCardsData} />
          </div>
        </div>
        <div className="pt-[6rem] md:pt-[0px] bg-[#f9fafc]">
          <PageBottomCard
            title="开启大数据分析与营销科技之旅！"
            desc="立即注册，和神策数据专家一起探讨数字化！"
            leftButtonText="体验 Demo"
            leftButtonHref="/demo/demo.html"
            rightButtonText="预约演示"
            rightButtonHref="/form/parade.html"
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    overseasBusinessYaml {
      part1 {
        title
        desc
        banner {
          publicURL
        }
        buttons {
          text
          href
          btnType
          ghost
        }
      }
      part2 {
        title
        desc
        img {
          publicURL
        }
      }
      part2_qrcode {
        publicURL
      }
      part3 {
        tabs {
          tabName
        }
        sections {
          title
          subTitle
          tabs {
            dataTitle
            imgUrl {
              publicURL
            }
            imgAlt
            descList
            mbDescList
            buttonText
            buttonHref
          }
        }
      }
      part4 {
        imgUrl {
          publicURL
        }
        imgAlt
        title
        text
        signature
      }
      part5 {
        title
        tip
        url
        img {
          publicURL
        }
        alt
        text
      }
    }
  }
`

export default overseasBusiness
