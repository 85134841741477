import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useMount, useScroll } from 'ahooks'
import classnames from 'classnames'
import Swiper, { Mousewheel, Autoplay } from 'swiper'
import 'swiper/swiper.min.css'
import * as styles from './index.module.less'
import li1 from './svg/li1.svg'
import { Button } from 'components/index'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import SwiperCom, { SwiperComInstance } from '../../HealthCom/SwiperCom'

/**
 *
 * 从components的MousewheelSwiper复制过来，主要是调整成符合解决方案出海落地页的样式需求
 *
 */

Swiper.use([Mousewheel, Autoplay])

const LiIconMap = {
  default: li1,
}

interface MousewheelSwiperProps {
  className?: string
  swiperContentClassName?: string
  swiperClassName?: string
  progressBarHeight?: number
  dataSource: {
    imgUrl?: any
    imgAlt?: string
    dataTitle?: string
    dataDesc?: string
    descList?: string[]
    buttonHref?: string
    buttonText?: string
  }[]
}

const ItemContent = React.memo<any>(({ data }) => {
  const { imgUrl, imgAlt, dataTitle = '', descList = [], mbDescList = [], buttonText, buttonHref } = data || {}
  const descriptionList = mbDescList ?? descList
  return (
    <div className={styles.mbContentWrapper}>
      <div className={styles.itemContenWrapper}>
        <div className={styles.mbImg}>
          <img src={imgUrl?.publicURL ?? imgUrl} alt={imgAlt || ''} />
        </div>
        <div className={styles.mbDetailsWrapper}>
          <div className={styles.mbDataTitle}>{dataTitle}</div>
          {descList && descList.length > 0 && (
            <div className={styles.mbDescList}>
              {descriptionList.map((item) => (
                <div className={styles.mbDesc}>
                  {/* <div className={styles.mbIcon}>
                  <img src={LiIconMap['default']} />
                </div> */}
                  <div className={styles.mbText}>{item}</div>
                </div>
              ))}
            </div>
          )}
          {buttonText && (
            <div className={styles.buttons}>
              <Button btnType="primary" href={buttonHref}>
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
/**
 *
 * @param className
 * @param progressBarHeight 滚动条总高度
 * @param dataSource 源数据列表
 * @returns
 */
const MousewheelSwiper: React.FC<MousewheelSwiperProps> = ({
  dataSource,
  className,
  progressBarHeight = 216,
  swiperClassName,
  swiperContentClassName,
}) => {
  const position = useScroll(null)
  const mSwiper = useRef<Swiper>()
  const refBar = useRef<any>()
  const swiperComRef = useRef<SwiperComInstance | null>(null)
  const [current, setCurrent] = useState<number>(0)
  const scrollBarHeight = dataSource ? progressBarHeight / (dataSource.length || 1) : 0

  const isMb = useIsWindowWidthSmaller()

  useMount(() => {
    mSwiper.current = new Swiper('.mousewhell-swiper', {
      enabled: false,
      direction: 'vertical',
      slidesPerView: 1,
      speed: 500,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      mousewheel: {
        forceToAxis: true,
        releaseOnEdges: true,
        sensitivity: 1,
      },
      on: {
        slideChangeTransitionStart: (swiper) => {
          if (refBar && refBar.current) {
            refBar.current.style.height = scrollBarHeight

            setCurrent(swiper.activeIndex)
            refBar.current.style.transform = `translateY(${swiper.activeIndex * scrollBarHeight}px)`
          }
        },
      },
    })
  })

  useEffect(() => {
    const domRect = document.getElementById('mousewhell-swiper')?.getBoundingClientRect()
    const clientHight = document.documentElement.clientHeight
    if (domRect) {
      // 判断元素全部在可视区域内
      if (domRect.top > 72 && domRect.bottom < clientHight && domRect.bottom > domRect.height + 72) {
        mSwiper?.current?.enable()
      } else {
        mSwiper?.current?.disable()
      }
    }
  }, [position?.top])

  const handleHover = (index: number) => {
    mSwiper?.current?.slideTo(index)
  }

  // 移动端滑动组件
  const nodes = useMemo(() => {
    return dataSource.map((item, index) => <ItemContent data={item} key={index} />)
  }, [dataSource])

  //当数据资源发生改变的时候将轮播切换到第一个
  useEffect(() => {
    handleHover(0)
    setCurrent(0)
    swiperComRef.current?.slideTo(0)
  }, [dataSource])

  return isMb ? (
    <div className={styles.mbWrapper}>
      <SwiperCom ref={swiperComRef} className={styles.contentWrapper} nodes={nodes} />
    </div>
  ) : (
    <div id="mousewhell-swiper-container" className={classnames(styles.MousewheelSwiperContainer, className)}>
      <div className="mousewhell-swiper-left">
        <div className="progress-bar-wrap">
          <div className="mousewhell-swiper-imgs">
            {dataSource?.map((item, index) => (
              <div className="mousewhell-swiper-imgs-absolute">
                <img
                  className={classnames({ active: current === index })}
                  key={index}
                  src={item.imgUrl?.publicURL}
                  alt={item.imgAlt}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={`progress-bar`} style={{ height: progressBarHeight }}>
          <span
            className="cur-bar"
            style={{ height: scrollBarHeight }}
            ref={(value) => (refBar.current = value)}
          ></span>
          <div className={'progress-bar-hover'}>
            {dataSource?.map((item, index) => (
              <div key={index} onMouseEnter={() => handleHover(index)} style={{ height: scrollBarHeight }}></div>
            ))}
          </div>
        </div>
      </div>
      <div
        id="mousewhell-swiper"
        className={classnames(styles.MousewheelSwiper, 'swiper-container mousewhell-swiper', swiperClassName)}
      >
        <div className="swiper-wrapper">
          {dataSource?.map((item) => (
            <div className="swiper-slide" key={item.dataTitle}>
              <div className={classnames('swiper-content', swiperContentClassName)}>
                <h2 className="whitespace-nowrap ">{item.dataTitle}</h2>
                {item?.dataDesc && (
                  <p className="w-max max-w-[361px] max-h-full mt-[10px] font-light text-[#1F2D3D] text-[20px] leading-[30px]">
                    {item.dataDesc}
                  </p>
                )}
                <ul className={classnames({ 'mt-[20px]': item?.dataDesc })}>
                  {item?.descList?.map((desc) => (
                    <li key={desc}>
                      <div className={styles.icon}>
                        <img src={LiIconMap['default']} />
                      </div>
                      {desc}
                    </li>
                  ))}
                </ul>
                <div className="swiper-btn">
                  <Button btnType="primary" href={item.buttonHref} target="_blank">
                    {item.buttonText}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MousewheelSwiper
