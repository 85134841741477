// extracted by mini-css-extract-plugin
export var MousewheelSwiper = "index-module--MousewheelSwiper--llekP";
export var MousewheelSwiperContainer = "index-module--MousewheelSwiperContainer--WFUJX";
export var buttons = "index-module--buttons--s6+Sf";
export var icon = "index-module--icon--x-ze0";
export var itemContenWrapper = "index-module--itemContenWrapper--+f8TM";
export var mbContentWrapper = "index-module--mbContentWrapper--D6K7K";
export var mbDataTitle = "index-module--mbDataTitle--HKY9I";
export var mbDescList = "index-module--mbDescList--cA5j3";
export var mbDetailsWrapper = "index-module--mbDetailsWrapper--Qw7Zi";
export var mbImg = "index-module--mbImg--uzyMk";
export var mbText = "index-module--mbText--EbfTl";
export var mbWrapper = "index-module--mbWrapper--B68Qu";