import React, { useMemo, useState, useRef } from 'react'
import classNames from 'classnames'
import * as styles from './index.module.less'
// import MousewheelSwiper from 'components/MousewheelSwiper'
import MousewheelSwiper from '../MousewheelSwiper'
import { isArray } from 'lodash'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

interface SectionsProps {
  /**标题 */
  title: string
  /**副标题 */
  subTitle: string
  tabs: {
    dataTitle: string
    imgUrl: any
    imgAlt: string
    descList: string[]
    buttonText: string
    buttonHref: string
  }[]
}

interface MousewheelTabSwiperProps {
  /**Tabs tabName：tab名称；tabUrl：tab跳转链接 */
  tabs?: { tabName: string; tabUrl?: string }[]
  /** MousewheelSwiper数据*/
  sections: SectionsProps[]
}

const MousewheelTab: React.FC<MousewheelTabSwiperProps> = (props) => {
  const { tabs, sections } = props

  const isMb = useIsWindowWidthSmaller()

  //统一写的是数组遍历，所以如果不是数组将数据放入数组里面
  const sectionsArr = isArray(sections) ? sections : [sections]

  //当前激活的tab
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const handleTabClick = (tabIndex) => {
    setActiveTabIndex(tabIndex)
  }

  return (
    <div className={styles.tabSwitcher}>
      <div className={styles.tabs}>
        {tabs &&
          tabs.map((tab, index) => (
            <div
              key={index}
              className={classNames({
                [styles.tab]: true,
                [styles.active]: activeTabIndex === index,
                'w-[300px]': true,
              })}
              onClick={() => handleTabClick(index)}
            >
              {tab.tabName}
            </div>
          ))}
      </div>
      <div>
        {sectionsArr.map((section) => {
          return (
            <div>
              <div className={classNames(styles.titleWrapper, { [styles.hasTabs]: tabs })}>
                <div className={styles.title}>{section.title}</div>
                {!isMb && <div className={styles.subTitle}>{section.subTitle}</div>}
              </div>
              <div className={styles.content}>
                <MousewheelSwiper dataSource={section.tabs} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MousewheelTab
