// extracted by mini-css-extract-plugin
export var active = "index-module--active--Khd7R";
export var bWarrper = "index-module--bWarrper--1ATcP";
export var banner = "index-module--banner--bKGda";
export var bannerWrapper = "index-module--bannerWrapper--PL08I";
export var btns = "index-module--btns--O1gv1";
export var cardCotent = "index-module--cardCotent--BkNYC";
export var cardDesc = "index-module--cardDesc--D8qLX";
export var cardTitle = "index-module--cardTitle--0uEI7";
export var cardWrapper = "index-module--cardWrapper--HfOMj";
export var imgWrapper = "index-module--imgWrapper--IlFCK";
export var overseasBusinessRoot = "index-module--overseasBusinessRoot--S9R6K";
export var part2 = "index-module--part2--j9hG-";
export var part2List = "index-module--part2List--52Fdj";
export var part3 = "index-module--part3--gvhG3";
export var part3tabs = "index-module--part3tabs--qi7MR";
export var partContent = "index-module--partContent--DqyqV";
export var subTitle = "index-module--subTitle--bJ-2R";
export var tab = "index-module--tab--nbmHF";
export var tabs = "index-module--tabs--zDGYL";
export var title = "index-module--title--wWkSx";
export var titleWrapper = "index-module--titleWrapper--AL4BP";
export var wWarrper = "index-module--wWarrper--aheJe";